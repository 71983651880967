import type {
  RoutineVariable,
  VariableTypeMap,
} from '@sb/remote-control/types';
import type { TRoutineVariablesIdMap } from '@sb/routine-runner/util/createRoutineVariablesMap';

type TConvertPythonResult = {
  code: string;
  errors: string[];
};

type TRoutineTypesConverted =
  | RoutineVariable.SpaceVariableInformation
  | RoutineVariable.EnvironmentVariableInformation
  | RoutineVariable.StepVariableInformation;

type TVariableConvert = Pick<VariableTypeMap, 'space' | 'environment' | 'step'>;

const VAR_TYPE_CONVERT: Record<string, keyof TVariableConvert> = {
  variables: 'environment',
  steps: 'step',
};

const convertVarType = (varType: string): keyof TVariableConvert => {
  const c = VAR_TYPE_CONVERT[varType];

  if (c == null) {
    return varType as keyof TVariableConvert;
  }

  return c;
};

const getVariableName = (v: TRoutineTypesConverted): string => {
  const { kind } = v;

  switch (kind) {
    case 'environment':
      return v.environmentVariable.name;
    case 'space':
      return v.spaceItem.name;
    case 'step':
      return `${v.stepPosition}`;
    default: {
      const exhaustiveCheck: never = kind;
      throw new Error(`Unhandled variable case: ${exhaustiveCheck}`);
    }
  }
};

const getVariableId = (v: TRoutineTypesConverted): string => {
  const { kind } = v;

  switch (kind) {
    case 'environment':
      return v.environmentVariable.id;
    case 'space':
      return v.spaceItem.id;
    case 'step':
      return v.stepID;
    default: {
      const exhaustiveCheck: never = kind;
      throw new Error(`Unhandled variable case: ${exhaustiveCheck}`);
    }
  }
};

const TO_USER_REGEX =
  /routine\.(variables|space|steps)\.id\s*\[\s*['"]([^'"]+)['"]\s*,\s*['"]([^'"]+)['"]\s*\]/g;

const FROM_USER_REGEX =
  /routine\.(variables|space|steps)\s*\[\s*['"]([^'"]+)['"]\s*\]/g;

/**
 * Python expression GUID conversion with variables available in remote control.
 */
export namespace withRemoteControlVariables {
  /**
   * [IDs->Names] Convert code block/expression that has internal IDs to one that uses names.
   */
  export const toUser = (
    code: string,
    variables: TVariableConvert,
  ): TConvertPythonResult => {
    const errors: string[] = [];

    const res = code.replace(
      TO_USER_REGEX,
      (match, varType, capturedId, capturedName) => {
        const typeMapping = variables[convertVarType(varType)] as
          | Array<TRoutineTypesConverted>
          | undefined;

        if (typeMapping == null) {
          // This is a developer error.
          throw new Error(`Failed to match routine type to match='${match}'`);
        }

        const variable = typeMapping.find(
          (v) => getVariableId(v) === capturedId,
        );

        if (variable == null) {
          errors.push(`Unknown '${varType}': '${capturedName}'`);

          // The ID is invalid. E.g. the step, space, or variable was deleted since it was first added.
          // Return something that is picked up by intellisense quickly while giving the user some idea what they had previously.
          return `routine.${varType}[Invalid ${varType}: "${capturedName}"]`;
        }

        const name = getVariableName(variable);

        return `routine.${varType}["${name}"]`;
      },
    );

    return { code: res, errors };
  };

  /**
   * [Names->IDs] Convert a code block/expression with names to one with internal IDs.
   */
  export const fromUser = (
    code: string,
    variables: TVariableConvert,
  ): TConvertPythonResult => {
    const errors: string[] = [];

    const res = code.replace(FROM_USER_REGEX, (match, varType, varname) => {
      const typeMapping = variables[convertVarType(varType)] as
        | Array<TRoutineTypesConverted>
        | undefined;

      if (typeMapping == null) {
        // This is a developer error.
        throw new Error(`Failed to match routine type to match='${match}'`);
      }

      const variable = typeMapping.find((v) => getVariableName(v) === varname);

      if (variable == null) {
        errors.push(`Unknown '${varType}': '${varname}'`);

        // Users will often pass invalid variable names. For example, if they save in the middle of writing a valid name.
        // Let's not try to fix these user errors. Just leave as-is.
        return `routine.${varType}["${varname}"]`;
      }

      const id = getVariableId(variable);

      return `routine.${varType}.id["${id}", "${varname}"]`;
    });

    return {
      code: res,
      errors,
    };
  };
}

export namespace withIdNameMap {
  /**
   * [IDs->Names] Convert code block/expression that has internal IDs to one that uses names.
   */
  export const toUser = (
    code: string,
    variables: TRoutineVariablesIdMap,
  ): TConvertPythonResult => {
    const errors: string[] = [];

    const res = code.replace(
      TO_USER_REGEX,
      (match, varType, capturedId, capturedName) => {
        const typeMapping = variables[convertVarType(varType)];

        if (typeMapping == null) {
          // This is a developer error.
          throw new Error(`Failed to match routine type to match='${match}'`);
        }

        const name = typeMapping[capturedId];

        if (name == null) {
          errors.push(`Unknown '${varType}': '${capturedName}'`);

          // The ID is invalid. E.g. the step, space, or variable was deleted since it was first added.
          // Return something that is picked up by intellisense quickly while giving the user some idea what they had previously.
          return `routine.${varType}[Invalid ${varType}: "${capturedName}"]`;
        }

        return `routine.${varType}["${name}"]`;
      },
    );

    return { code: res, errors };
  };

  /**
   * [Names->IDs] Convert a code block/expression with names to one with internal IDs.
   *
   * Can use in concert with `createRoutineVariablesMap#getNameIdMap`
   */
  export const fromUser = (
    code: string,
    variables: TRoutineVariablesIdMap,
  ): TConvertPythonResult => {
    const errors: string[] = [];

    const res = code.replace(FROM_USER_REGEX, (match, varType, varname) => {
      const typeMapping = variables[convertVarType(varType)];

      if (typeMapping == null) {
        // This is a developer error.
        throw new Error(`Failed to match routine type to match='${match}'`);
      }

      const id = typeMapping[varname];

      if (id == null) {
        errors.push(`Unknown '${varType}': '${varname}'`);

        // Users will often pass invalid variable names. For example, if they save in the middle of writing a valid name.
        // Let's not try to fix these user errors. Just leave as-is.
        return `routine.${varType}["${varname}"]`;
      }

      return `routine.${varType}.id["${id}", "${varname}"]`;
    });

    return {
      code: res,
      errors,
    };
  };
}
