import { z } from 'zod';

export const CustomDeviceStateSchema = z.object({
  kind: z.literal('CustomDevice'),
  status: z.enum(['initializing', 'idle', 'busy', 'error']),
  modbusTCPConnection: z
    .object({
      isConnected: z.boolean(),
    })
    .optional(),
  message: z.string().optional(),
});

export type CustomDeviceState = z.infer<typeof CustomDeviceStateSchema>;
