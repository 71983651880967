import { v4 as uuid } from 'uuid';

import type { EquipmentData } from '@sb/feathers-types';
import { applyCompoundPose } from '@sb/geometry';
import {
  OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
  OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
} from '@sb/integrations/implementations/onRobot/OnRobotDualQuickChanger/constants';
import type { DeviceConfiguration } from '@sb/integrations/types';
import { EquipmentItem } from '@sb/integrations/types';

// apply additional transforms to dual qc primary/secondary gripper defaults
function fixDualQCDefaultTCPTransform(
  config: any,
  parsedConfig: DeviceConfiguration,
) {
  if (parsedConfig.kind === 'OnRobotDualQuickChanger') {
    if (
      'tcpTransform' in parsedConfig.grippers.primary &&
      !config.grippers?.primary?.tcpTransform
    ) {
      // eslint-disable-next-line no-param-reassign
      parsedConfig.grippers.primary.tcpTransform = applyCompoundPose(
        parsedConfig.grippers.primary.tcpTransform,
        OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
      );
    }

    if (
      'tcpTransform' in parsedConfig.grippers.secondary &&
      !config.grippers?.secondary?.tcpTransform
    ) {
      // eslint-disable-next-line no-param-reassign
      parsedConfig.grippers.secondary.tcpTransform = applyCompoundPose(
        parsedConfig.grippers.secondary.tcpTransform,
        OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
      );
    }
  }
}

// We should be able to remove this after a while once existing custom grippers
// are updated to use the new format
function addCustomEquipmentIds(equipment: EquipmentData) {
  const equipmentWithIds = { ...equipment };

  if (
    equipment.config.kind === 'CustomGripper' ||
    equipment.config.kind === 'CustomDevice'
  ) {
    if (Array.isArray(equipment.config.actions)) {
      equipmentWithIds.config.actions = equipment.config.actions.map(
        (action) => {
          if (action.id != null) {
            return action;
          }

          return {
            ...action,
            id: uuid(),
          };
        },
      );
    }

    if (Array.isArray(equipment.config.sensors)) {
      equipmentWithIds.config.sensors = equipment.config.sensors.map(
        (sensor) => {
          if (sensor.id != null) {
            return sensor;
          }

          return {
            interface: {
              ...sensor,
            },
            id: uuid(),
          };
        },
      );
    }
  }

  return equipmentWithIds;
}

export function convertEquipmentResponse(
  equipment: EquipmentData,
): EquipmentItem {
  const equipmentWithIds = addCustomEquipmentIds(equipment);
  const parsedEquipment = EquipmentItem.safeParse(equipmentWithIds);

  if (parsedEquipment.success) {
    fixDualQCDefaultTCPTransform(
      equipmentWithIds.config,
      parsedEquipment.data.config,
    );

    return parsedEquipment.data;
  }

  return {
    id: equipment.id,
    config: {
      kind: 'InvalidConfiguration',
      name: equipment.config.name ?? equipment.config.kind,
      invalidConfiguration: equipment.config,
    },
    isEnabled: false,
  };
}
