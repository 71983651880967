import * as zod from 'zod';

import { ZERO_ROTATION } from '@sb/geometry';

import type { OnRobot2FG14Command } from './types';

export const OR2FG14_SHORTNAME = '2FG14';

export const ONROBOT_TCP_PORT_DEFAULT = 502;

export const OR2FG14_MIN_SPEED = 0.16;
export const OR2FG14_MAX_SPEED = 4.5;

export const OR2FG14_PRODUCT_CODE_NUMBER = 193;

// Percent, from 10% to 100%
export const OR2FG14Speed = zod
  .number()
  .min(OR2FG14_MIN_SPEED)
  .max(OR2FG14_MAX_SPEED);

export type OR2FG14Speed = zod.infer<typeof OR2FG14Speed>;

export const OR_2FG14_DIAMETER_DEFAULT = 0.25;

export const OR_2FG14_DIAMETER_METERS_SLIDER_STEP = 0.001;

// newtons
export const FORCE_RANGE = {
  min: 40,
  max: 280,
};

// Ranges are with 5mm the stock fingers, not including the silicone covers
// page 2 in datasheet: https://onrobot.com/sites/default/files/documents/Datasheet_2FG14_v1.4_EN.pdf
export const OUTWARD_MOUNT_EXTERNAL_GRIP_WIDTH_RANGE_METERS = {
  // External - Fingers outwards
  min: 0.055,
  max: 0.105,
};

export const OUTWARD_MOUNT_INTERNAL_GRIP_WIDTH_RANGE_METERS = {
  // Internal - Fingers outwards
  min: 0.0676,
  max: 0.1176,
};

export const INWARD_MOUNT_EXTERNAL_GRIP_WIDTH_RANGE_METERS = {
  // External - Fingers inwards
  min: 0.005,
  max: 0.055,
};

export const INWARD_MOUNT_INTERNAL_GRIP_WIDTH_RANGE_METERS = {
  // Internal - Fingers inwards
  min: 0.0176,
  max: 0.0676,
};

export const OR2FG14GripKind = zod.union([
  zod.literal('inward'),
  zod.literal('outward'),
]);

export type OR2FG14GripKind = zod.infer<typeof OR2FG14GripKind>;

export const OR_2FG14_GRIP_KINDS_DEFAULT: OR2FG14GripKind = 'inward';

export const COMMAND_DEFAULT: OnRobot2FG14Command = {
  kind: 'OnRobot2FG14Command',
  gripKind: OR_2FG14_GRIP_KINDS_DEFAULT,
  targetForce: (FORCE_RANGE.min + FORCE_RANGE.max) / 2,
  targetDiameter:
    (OUTWARD_MOUNT_EXTERNAL_GRIP_WIDTH_RANGE_METERS.min +
      OUTWARD_MOUNT_EXTERNAL_GRIP_WIDTH_RANGE_METERS.max) /
    2,
  targetSpeed: OR2FG14Speed.maxValue!,
};

export const OR_2FG14_FORCE_NEWTONS_MIN = 40;
export const OR_2FG14_FORCE_NEWTONS_MAX = 280;
export const OR_2FG14_FORCE_NEWTONS_SLIDER_STEP = 1;
export const OR_2FG14_TARGET_FORCE_DEFAULT = 45; // Newton equivalent of 10 lbs

// confirmed default registers for 2FG gripper
export const OR_2FG14_FINGER_LENGTH_METERS_DEFAULT = 0.0125;
export const OR_2FG14_FINGER_HEIGHT_METERS_DEFAULT = 0.052;

// offset includes silicon fingertip covers, which increase 5mm fingertip by 1mm in each direction
// noted in page 3 of datasheet: https://onrobot.com/sites/default/files/documents/Datasheet_2FG14_EN.pdf
export const OR_2FG14_FINGERTIP_OFFSET_METERS_DEFAULT = 0.007;

export const OR_2FG14_FINGER_ANGLE_DEGREES_DEFAULT = 0;

export const ACTUATION_TIMEOUT_MS = 5000;

export const RETRY_INTERVAL_MS = 10000;

export const DEFAULT_OR2FG14_DIAMETER_TOLERANCE_METERS = 0.003;
// Page 7 of connectivity doc
export const OR2FG14_TARGET_ADDRESS = 0x41;
// how often to request state variables (maximum)

export const INITIALIZATION_TIMEOUT_MS = 5000;

// silence over modbus = disconnected
export const MODBUS_TIMEOUT_MS = 5_000;

export enum Register {
  // Write-only
  TARGET_WIDTH = 0x0000, // 1/10th mm
  TARGET_FORCE = 0x0001, // N
  TARGET_SPEED = 0x0002, // percentage from 10-100%
  COMMAND = 0x0003, // 1, 2, or 3

  // Read-only
  STATUS = 0x0100, // decomposed bitwise
  EXTERNAL_WIDTH = 0x0101, // 1/10th mm
  INTERNAL_WIDTH = 0x0102, // 1/10th mm

  MIN_EXTERNAL_WIDTH = 0x0103, // 1/10th mm
  MAX_EXTERNAL_WIDTH = 0x0104, // 1/10th mm
  MIN_INTERNAL_WIDTH = 0x0105, // 1/10th mm
  MAX_INTERNAL_WIDTH = 0x0106, // 1/10th mm
  MAX_FORCE = 0x406, // N
  PRODUCT_CODE_IDENTIFIER = 0x600, //  2FG -> returns 192
  FORCE = 0x0107, // N
  MAX_AVAILABLE_FORCE = 0x405, // 50 if force locked ; 280 if unlocked

  // Read/write
  FINGER_LENGTH = 0x400, // 1/10th mm
  FINGER_HEIGHT = 0x401, // 1/10th mm
  FINGER_ORIENTATION = 0x402, // 0 or 1
  FINGERTIP_OFFSET = 0x403, // 1/100th mm
  UNLOCK_MAX_AVAILABLE_FORCE = 0x404, // 50 if force locked ; 280 if unlocked
  FINGER_ANGLE = 0x407, // 90 degrees
}

export enum OR2FG14_CONTROL_COMMAND {
  GRIP_INWARD = 0x01,
  GRIP_OUTWARD = 0x02,
  STOP = 0x03,
}

export enum OR2FG14_FINGER_ORIENTATION {
  INWARD = 0x00,
  OUTWARD = 0x01,
}

export const OR2FG14FingerOrientation = zod.union([
  zod.literal('inward'),
  zod.literal('outward'),
]);

export type OR2FG14FingerOrientation = zod.infer<
  typeof OR2FG14FingerOrientation
>;

export const OR_2FG14_FINGER_POSITION_DEFAULT: OR2FG14FingerOrientation =
  'outward';

export enum OR2FG14_STATUS_BITS {
  BUSY = 0,
  GRIP_DETECTED = 1,
  ERROR_NOT_CALIBRATED = 3,
  ERROR_LINEAR_SENSOR = 4,
}

export const M_TO_TENTH_MM = 10_000;
export const TENTH_MM_TO_M = 1 / M_TO_TENTH_MM;

export const M_TO_HUNDREDTH_MM = 100_000;
export const HUNDREDTH_MM_TO_M = 1 / M_TO_HUNDREDTH_MM;

export const OR2FG14_DEFAULT_TCP_TRANSFORM = {
  ...ZERO_ROTATION,
  x: 0,
  y: 0,
  z: 0.125,
};
